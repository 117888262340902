import { Controller } from "stimulus"

/**
 * Shows the annotations on the web dashboard
 *
 * Different annotation formats:
 *
 * Sandbox
 * -> [{"annotation_value"=>{"answer"=>"{\"points\":[[39.40161,34.846214],[112.860596,78.4402]],\"segment_ratio_list\":[],\"type\":\"BOUNDING_BOX\"}"}}]
 *
 * DataRecords
 * -> [{"answer"=>"{\"points\":[[39.40161,34.846214],[112.860596,78.4402]],\"segment_ratio_list\":[],\"type\":\"BOUNDING_BOX\"}"}]
 * -> {"answer"=>"{\"points\":[[39.40161,34.846214],[112.860596,78.4402]],\"segment_ratio_list\":[],\"type\":\"BOUNDING_BOX\"}"}
 * -> [{"answer"=>"junk"}]
 * -> [{"answer"=>{"mask_url": "/var/shared_data/seatbelt_mask/seatbelt_test.png", "type": "MASK"}}]
 * -> {"answer"=>"{\"input_list\":[],\"points\":[[293.12982,68.377525],[258.20987,100.708115],[223.28989,133.03871]],\"segment_ratio_list\":[],\"thickness_ratio\":10.0,\"type\":\"CONNECTED_LINE\"}"}
 *
 */
export default class extends Controller {
    static targets = [ "image", "canvas", "reload" ]
    static values = { annotation: String }

    initialize(){
        this.drawImage();
    }

    drawImage() {
        const canvas = this.canvasTarget;
        const img = this.imageTarget;

        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        this.ctx = canvas.getContext("2d");

        const hRatio = canvas.width / img.width;
        const vRatio = canvas.height / img.height;
        this.ratio = Math.min(hRatio, vRatio);

        // Draw image
        this.scaled_img_height = img.height * this.ratio;
        this.scaled_img_width = img.width * this.ratio;

        const xOffset = (canvas.width - this.scaled_img_width) / 2
        const yOffset = (canvas.height - this.scaled_img_height) / 2

        this.ctx.drawImage(img, xOffset, yOffset, this.scaled_img_width, this.scaled_img_height);

        if (this.annotationValue) {
            this.drawAnnotation(this.annotationValue, xOffset, yOffset)
        }

        this.reloadTarget.classList.add("d-none");
    }

    drawAnnotation(annotation, xOffset, yOffset) {
        if (Array.isArray(annotation)) {
            annotation.forEach(a => this.drawAnnotation(a, xOffset, yOffset))
        } else {
            switch (typeof annotation) {
                case "number":
                    this.drawStringAnnotation(annotation, xOffset, yOffset)
                    break
                case "string":
                    try {
                        // If we are able to parse the string, that means it is actually JSON. Else, draw string.
                        let parsed = JSON.parse(annotation);
                        this.drawAnnotation(parsed, xOffset, yOffset);
                    } catch (error) {
                        this.drawStringAnnotation(annotation, xOffset, yOffset)
                    }
                    break;
                case "object":
                    if (annotation['annotation_value']) {
                        this.drawAnnotation(annotation['annotation_value'], xOffset, yOffset)
                    } else {
                        let answer = annotation['answer'] || annotation
                        if (typeof answer === "string") {
                            this.drawAnnotation(answer, xOffset, yOffset)
                        } else {
                            switch (answer['type']) {
                                case 'QUADRILATERAL':
                                    this.drawQuadrilateralAnnotation(answer, xOffset, yOffset)
                                    break;
                                case 'BOUNDING_BOX':
                                    this.drawBoundingBoxAnnotation(answer, xOffset, yOffset)
                                    break;
                                case 'SPLIT_BOX':
                                    this.drawSplitBox(answer, xOffset, yOffset)
                                    break;
                                case 'MASK':
                                    this.drawMask(answer, xOffset, yOffset)
                                    break;
                                case 'CONNECTED_LINE':
                                    this.drawConnectedLineAnnotation(answer, xOffset, yOffset)
                                    break;
                            }
                        }
                    }
                    break;
            }
        }
    }

    drawStringAnnotation(annotation, xOffset, yOffset) {
        this.ctx.font = "20px Georgia";
        this.ctx.fillStyle = "#9BFF31";
        this.ctx.fillText(annotation, 10 + xOffset, 20 + yOffset);
    }

    drawQuadrilateralAnnotation(annotation, xOffset, yOffset) {
        // Draw BBoxes for points given
        const points = annotation['points'];

        this.ctx.strokeStyle = "#9BFF31";
        this.ctx.lineWidth = 3;

        this.ctx.beginPath();
        this.ctx.moveTo((points[0][0] * this.ratio) + xOffset, (points[0][1] * this.ratio) + yOffset);
        this.ctx.lineTo((points[1][0] * this.ratio) + xOffset, (points[1][1] * this.ratio) + yOffset);
        this.ctx.lineTo((points[2][0] * this.ratio) + xOffset, (points[2][1] * this.ratio) + yOffset);
        this.ctx.lineTo((points[3][0] * this.ratio) + xOffset, (points[3][1] * this.ratio) + yOffset);
        this.ctx.closePath();
        this.ctx.stroke();
    }

    drawConnectedLineAnnotation(annotation, xOffset, yOffset) {
        const linePointCount = annotation['points'].length;
        const points = annotation['points']
        console.log(linePointCount);

        this.ctx.strokeStyle = "#9BFF31";
        this.ctx.lineWidth = 3;

        var i = 1;
        this.ctx.beginPath();
        this.ctx.moveTo((points[0][0] * this.ratio) + xOffset, (points[0][1] * this.ratio) + yOffset);
        for(i = 1; i < linePointCount; i++) {
            if (i % 2 == 0) {
                this.ctx.quadraticCurveTo(
                    (points[i-1][0] * this.ratio) + xOffset, (points[i-1][1] * this.ratio) +yOffset,
                    (points[i][0] * this.ratio) + xOffset, (points[i][1] * this.ratio) + yOffset)
            } else
                continue
        }
        this.ctx.stroke();
    }

    drawBoundingBoxAnnotation(annotation, xOffset, yOffset) {
        // Draw BBoxes for points given
        const points = annotation['points'];
        const label = annotation['input'];

        const bbox_top_left_x = (points[0][0] * this.ratio) + xOffset;
        const bbox_top_left_y = (points[0][1] * this.ratio) + yOffset;
        const bbox_width = (points[1][0] - points[0][0]) * this.ratio;
        const bbox_height = (points[1][1] - points[0][1]) * this.ratio;
        this.ctx.strokeStyle = "#9BFF31";
        this.ctx.lineWidth = 3;
        this.ctx.strokeRect(
            bbox_top_left_x,
            bbox_top_left_y,
            bbox_width,
            bbox_height
        );

        this.ctx.lineWidth = 1;
        this.ctx.font = "30px Georgia";
        if (label) {
            this.ctx.strokeText(label, bbox_top_left_x + 2, bbox_top_left_y - 4);
        }
    }

    drawSplitBox(annotation, xOffset, yOffset) {
        const points = annotation['points'].map(point => [point[0] * this.ratio, point[1] * this.ratio]);
        const labels = annotation['input_list'];

        // First draw the outer quadrilateral
        this.ctx.strokeStyle = "#9BFF31";
        this.ctx.lineWidth = 2;

        this.ctx.beginPath();
        this.ctx.moveTo((points[0][0]) + xOffset, (points[0][1]) + yOffset);
        this.ctx.lineTo((points[1][0]) + xOffset, (points[1][1]) + yOffset);
        this.ctx.lineTo((points[2][0]) + xOffset, (points[2][1]) + yOffset);
        this.ctx.lineTo((points[3][0]) + xOffset, (points[3][1]) + yOffset);
        this.ctx.closePath();
        this.ctx.stroke();

        // Next, draw all the segments and labels
        const segments = annotation['segment_ratio_list']
        this.ctx.font = "10px Georgia";
        let offset = 0
        var i;

        this.ctx.lineWidth = 1;
        if (labels) {
            this.ctx.strokeText(labels[0], points[0][0] + xOffset + 2, points[0][1] + yOffset - 4);
        }

        for (i = 0; i < segments.length; i++) {
            offset += segments[i]
            let topX = points[0][0] + (points[1][0] - points[0][0]) * offset / segments.length
            let topY = points[0][1] + (points[1][1] - points[0][1]) * offset / segments.length
            let bottomX = points[3][0] + (points[2][0] - points[3][0]) * offset / segments.length
            let bottomY = points[3][1] + (points[2][1] - points[3][1]) * offset / segments.length

            this.ctx.lineWidth = 2;
            this.ctx.beginPath();
            this.ctx.moveTo(topX + xOffset, topY + yOffset);
            this.ctx.lineTo(bottomX + xOffset, bottomY + yOffset);
            this.ctx.stroke();

            this.ctx.lineWidth = 1;
            if (labels && i < labels.length - 1) {
                this.ctx.strokeText(labels[i + 1], topX + xOffset + 2, topY + yOffset - 4);
            }
        }
    }

    drawMask(annotation, xOffset, yOffset) {
        const maskUrl = annotation['mask_url'];
        const maskImage = new Image();
        maskImage.onload = () => {
            this.ctx.drawImage(maskImage, xOffset, yOffset, this.scaled_img_width, this.scaled_img_height);
        }
        maskImage.src = maskUrl;
    }
}
