import consumer from "./consumer"

consumer.subscriptions.create("ProdRtsp5Channel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    var anpr = document.getElementById('prod_rtsp_5');
    var elm = document.createElement('tr');
    elm.innerHTML +=
      "<td>" + data.id + "</td>" +
      "<td>" + data.captured_on + "</td>" +
      "<td>" + data.camera_name + "</td>" +
      "<td><b>" + data.lp_number + "</b></td>";
    var lpTd = document.createElement('td');
    var img1 = document.createElement('img');
    img1.src = data.lp_image;
    img1.className = 'zoom';
    img1.setAttribute("style", "height: 64px;");
    lpTd.append(img1);
    var vehicleTd = document.createElement('td');
    var img2 = document.createElement('img');
    img2.src = data.vehicle_image;
    img2.className = 'zoom';
    img2.setAttribute("style", "height: 64px;");
    vehicleTd.append(img2);
    elm.append(lpTd);
    elm.append(vehicleTd);
    anpr.prepend(elm);
  }
});