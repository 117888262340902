
import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.sortable = Sortable.create(this.element,{
      onEnd: this.end.bind(this),
    })
  }

  end(event){
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("priority", event.newIndex)
    Rails.ajax({
      url: this.data.get("url").replace(":id",id),
      type: 'PATCH',
      data: data
    })
    console.log(event)
  }
}
